canvas {
  border: 1px solid #aaa;
  background: white;
}

body {
 margin: auto;
 width: 514px;
 font-size: 16px;
 font-family: "Helvetica Neue", sans-serif;
}

h1 {
  font-size: 1.5em;
  margin-bottom: 0;
}

h2 {
  text-align: right;
  font-size: 1em;
  /* Very hacky, try to change baseline instead */
  margin-top: -1.25em;
}

a {
  text-decoration: underline;;
  color: inherit;
}

figure {
  margin: 0;
  background-color: #aaa;
}

figcaption {
  font-size: 1em;
  padding: 0.25em;
  color: white;
  text-align: center;
}

nav h3 {
  margin-bottom: -0.5em;
}

nav ul {
  list-style-type: none;
}
