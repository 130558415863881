canvas {
  background: #fff;
  border: 1px solid #aaa;
}

body {
  width: 514px;
  margin: auto;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
}

h1 {
  margin-bottom: 0;
  font-size: 1.5em;
}

h2 {
  text-align: right;
  margin-top: -1.25em;
  font-size: 1em;
}

a {
  color: inherit;
  text-decoration: underline;
}

figure {
  background-color: #aaa;
  margin: 0;
}

figcaption {
  color: #fff;
  text-align: center;
  padding: .25em;
  font-size: 1em;
}

nav h3 {
  margin-bottom: -.5em;
}

nav ul {
  list-style-type: none;
}

/*# sourceMappingURL=index.5fb33637.css.map */
